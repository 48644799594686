import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/chateaudemorey/src/components/Layouts/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Partenaires et régions`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.tourisme-lorraine.fr"
        }}>{`Lorraine Tourisme`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.lorraine-reception.fr/"
        }}>{`lorraine-reception`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.marcotullio-traiteur.com/"
        }}>{`marcotullio-traiteur`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.lautrecanalnancy.fr/"
        }}>{`L’autre Canal`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.zenith-de-nancy.com/"
        }}>{`Zénith de Nancy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.centrepompidou.fr/"
        }}>{`Centre Pompidou`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.chateauluneville.meurthe-et-moselle.fr/"
        }}>{`Château de Lunéville`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fr.wikipedia.org/wiki/Palais_des_congr%C3%A8s_de_Nancy"
        }}>{`Palais des Congrès`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://lerelaisgourmand.fr/"
        }}>{`Le relais gourmand`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/LetSphotographie/"
        }}>{`L&S Photographie`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.julienmaria.fr"
        }}>{`Julien Maria Photographie`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.instagram.com/lacasitaemi/"
        }}>{`La Casita D’Emi`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      